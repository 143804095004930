.congrats {
    position:fixed;
    padding:0px 30px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(51, 51, 51, 0.854);
    z-index: 7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .close{
    height: 25px;
    width: 25px;
    align-self: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background: var(--white-color);
    box-shadow: 0px 4px 4px rgba(97, 166, 65, 0.25);
    border-radius: 6px;
    max-width: 350px;
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    color: var(--grey-color);
    align-self: center;
  }

  .container h2, h3, h4{
    font-weight: 950;

  }


  .container button{
    background: var(--green-color);
    border: none;
    height: 30px;
    border-radius: 10px;
    max-width: 200px;
    width: 100%;
    color: var(--white-color);
  }
  

  