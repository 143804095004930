.badge{
    position: relative;
    border: 1px solid #61a641;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 15px;
}

.rewardWrap{
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #35323294;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper{
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}



.textWrap{
    font-size: 13px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
}

.progressBar{
    height: 12px;
    border-radius:30px ;
    background-color: white;
    z-index: 2;
}

.progress{
    font-size: 10px;
    height: 100%;
    text-align: end;
    /* width: 40%; */
    /* background-color: red; */
    border-radius:30px ;
}
.avatar{
    height: 80.5px;
    width: 80.5px;
}