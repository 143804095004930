.login {
  min-height: 100vh;
  background-image: url("../../assets/login-background.png");
  background-size: cover;
  background-color: #f0f0f0;
  padding-bottom: 50px;

}

.login .ChatBtn {
  bottom: 32px;
}

@media screen and (max-height: 668px) {
  .login {
    min-height: 120vh;
  }
}
