.CreateProfileModal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(51, 51, 51, 0.5);
  z-index: 7;
  display: grid;
  place-items: center;
}

.CreateProfileContainer {
  background: var(--white-color);
  box-shadow: 0px 4px 4px rgba(97, 166, 65, 0.25);
  border-radius: 16px;
  width: 316px;
  height: 544px;
  display: grid;
  place-items: center;
  padding: 1rem;
}

.CreateProfileContainer h2 {
  font-weight: 750;
  font-size: 1.25rem;
  text-align: center;
  color: var(--grey-color);
}

.CreateProfileContainer a {
  background-color: var(--green-color);
  text-align: center;
  color: var(--white-color);
  border-radius: 8px;
  width: 70%;
  padding: 0.7rem 2.6rem;
}

.CreateProfileContainer div {
  width: 40%;
  margin-left: auto;
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CreateProfileContainer div span:nth-child(1),
.CreateProfileContainer div span:nth-child(2) {
  font-weight: 750;
  font-size: 0.875rem;
}

.CreateProfileContainer div span:nth-child(1) {
  color: var(--grey-color);
}

.CreateProfileContainer div span:nth-child(2) {
  color: var(--red-color);
}
