

.App {
  min-height: 100vh;
  background-color: #f0f0f0;
  background-image: url("./assets/home-background-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 25px 5px;
}



/* .toast-message {
 width: 200px;
} */

/* @media screen and (min-width: 600px) {
  .App {
    display: none;
  }
}

@media screen and (max-height: 668px) {
  .App {
    min-height: 120vh;
  }

  .nav-padding {
    padding-bottom: 5rem;
  }
} */
