.wrapper{
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 250px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    padding:7px 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(97, 166, 65, 0.25);
    z-index: 5;
    top: -100%;
    transition: all 2s;
    
}

.appear{
    margin-top: 10px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 250px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    padding:7px 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(97, 166, 65, 0.25);
    z-index: 5;
    top: 0;
    animation:drop 1s ; 

}

@keyframes drop {
    from{
        top: -100%;
    }
    to {    
        top: 0;
    }
  }

.textWrap{
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 0px 7px;
}

.icon{
    /* color: rgb(205, 204, 204); */
    color: red;
    font-size: 20px;
    min-width: 20px;
}

.text{
    font-size: 13px;
    line-height: 20px;
}


.buttonWrap{
    display: flex;
    align-items: center;
    gap: 7px;
    align-self: flex-end;
    margin-top: 10px;
    padding: 0 5px;
}

.buttonWrap button:nth-child(1) {
    border: none;
    background-color: inherit;
  }

  .buttonWrap button:nth-child(2) {
    border: none;
    background-color: var(--green-color);
    color: white;
    height: 30px;

  }