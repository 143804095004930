.BottomNav {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  background-color: var(--white-color);
  z-index: 4;
}

.BottomNav ul {
  width: 85vw;
  margin: auto;
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BottomNav img {
  width: 110%;
  height: auto;
  object-fit: cover;
}

.BottomNav .active img {
  transform: scale(1.2);
}
